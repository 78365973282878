<template>
  <StaticPage>
    <el-card class="form_wrapper">
      <h2>{{ $t('reserPwMobile.header') }}</h2>
      <div class="from_box" v-if="formFlag">
        <el-form :model="ruleResetPasswordForm" :rules="rulesResetPassword" ref="ruleResetPasswordForm">
          <InputTemplate
            v-model="ruleResetPasswordForm.pass"
            show-password
            name="pass"
            :label="$t('common.field.newPw')"
            data-testid="pass"
          >
          </InputTemplate>
          <InputTemplate
            v-model="ruleResetPasswordForm.checkPass"
            show-password
            name="checkPass"
            :label="$t('common.field.confirmPw')"
            data-testid="checkPass"
          >
          </InputTemplate>
          <el-button @click="submitForm('ruleResetPasswordForm')" data-testid="submit">
            {{ $t('common.button.submit') }}
          </el-button>
        </el-form>
      </div>
      <div class="result" v-if="successFlag">
        <i class="icon el-icon-circle-check text-green mb-3"></i>
        <p v-html="$t('changePw.succ')"></p>
        <router-link :to="{ name: 'home' }" class="el-button" data-testid="bkToHm">
          {{ $t('common.button.bkToHm') }}
        </router-link>
      </div>
    </el-card>
    <PasswordRenewalReminderDialog
      :reminderDialogVisible.sync="reminderDialogVisible"
      @confirmSubmit="confirmSubmit"
    />
  </StaticPage>
</template>

<script>
import { rsa } from '@/util/encrypt';
import StaticPage from '@/components/template/staticPage/StaticPageDefault';
import { apiSubmitReseForgetPassword } from '@/resource';
import passwordValidator from 'password-validator';
import PasswordRenewalReminderDialog from '@/components/auth/PasswordRenewalReminderDialog.vue';

export default {
  name: 'ResetPasswordMobile',
  components: { StaticPage, PasswordRenewalReminderDialog },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else {
        if (!this.schema.validate(value)) {
          callback(new Error(this.$t('common.formValidation.newPwFormat')));
        }
      }
      callback();
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else if (value !== this.ruleResetPasswordForm.pass) {
        callback(new Error(this.$t('common.formValidation.confirmNotMatch')));
      }
      callback();
    };
    return {
      formFlag: true,
      successFlag: false,
      schema: '',
      ruleResetPasswordForm: {
        pass: '',
        checkPass: ''
      },
      rulesResetPassword: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }]
      },
      reminderDialogVisible: false
    };
  },
  methods: {
    confirmSubmit() {
      this.updatePassword();
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) this.reminderDialogVisible = true;
        else return false;
      });
    },
    updatePassword() {
      apiSubmitReseForgetPassword({
        newPassword: rsa(this.ruleResetPasswordForm.pass)
      }).then(resp => {
        if (resp.data.code == 0 && resp.data.data == true) {
          this.formFlag = false;
          this.successFlag = true;
        }
      });
    }
  },
  mounted() {
    this.schema = new passwordValidator();
    this.schema.is().min(8).is().max(32).has().uppercase().has().digits().has().not().spaces();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/loginClient/login.scss';
@import '@/assets/css/pages/resetPassword.scss';
</style>
