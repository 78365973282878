<template>
  <el-dialog :visible.sync="visible" width="500px" :before-close="handleClose" center>
    <div class="title">{{ $t('security.passwordRenewalReminder.header') }}</div>
    <div class="description">{{ $t('security.passwordRenewalReminder.desc', { days: limitDayNumber }) }}</div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="confirmSubmit">{{ $t('security.confirm') }}</el-button>
      <el-button plain class="marginLeft" @click="handleClose">{{ $t('security.cancel') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { apiGetCurrentSecurity } from '@/resource';

export default {
  name: 'PasswordRenewalReminderDialog',
  props: {
    reminderDialogVisible: Boolean
  },
  data() {
    return {
      visible: false,
      limitDayNumber: undefined
    };
  },
  watch: {
    reminderDialogVisible(bool) {
      if (bool) {
        this.getFreezePeriod().then(resp => {
          if (resp && this.limitDayNumber) {
            this.visible = bool;
          } else {
            this.confirmSubmit();
          }
        });
      }
    },
    visible(bool) {
      this.$emit('update:reminderDialogVisible', bool);
    }
  },
  methods: {
    handleClose() {
      this.visible = false;
    },
    confirmSubmit() {
      this.visible = false;
      this.$emit('confirmSubmit');
    },
    async getFreezePeriod() {
      try {
        const resp = await apiGetCurrentSecurity('updatePassword');
        if (resp.data.code === 0) {
          if (resp.data.data.ruleContent) {
            this.limitDayNumber = resp.data.data.ruleContent.withdraw.limitTime;
            return true;
          }
        } else {
          console.error('Get limit day number fails');
        }
        return false;
      } catch (error) {
        console.error('Error occurred while fetching data:', error);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.title {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 24px;
  line-height: 160%;
}
.description {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin: 0 30px;
  line-height: 160%;
}
.marginLeft {
  margin-left: 24px !important;
}
/deep/ .el-dialog__footer {
  padding-bottom: 60px;
}
</style>
