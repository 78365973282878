<template>
  <div id="loginClient">
    <div class="banner">
      <div class="login_inner">
        <div class="logo">
          <a href="https://www.monetamarkets.com/" target="_blank">
            <img src="@/assets/images/mo/logo.png" alt class="logoImg" />
          </a>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StaticPageDefault'
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/template/staticPage/staticPageDefault.scss';
</style>
